/**
 * All Content post type scripts required should be included here.
 *
 * This is the file that Webpack is compiling into article.[hash].js
 */

import articleScroll from "../../pubstack/assets/js/article/article-scroll";

jQuery(function () {
  articleScroll();
});
